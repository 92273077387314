<template>
	<header class="header">
		<div class="header-gnb">
			<div class="header-component">
				<div class="header-logo">
					<router-link to="/" class="logo-link">
						<i class="logo"></i>
						<span class="sr-only">살맛나는 행복쇼핑 동행축제 2024</span>
					</router-link>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'LoginHeader',
};
</script>
