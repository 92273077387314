<template>
	<div class="gachi gachi-signup" id="gachi" :class="{ 'gachi-login': isMobile }">
		<!-- s: header -->
		<login-header />
		<!-- e: header -->

		<router-view />

		<!-- s: footer -->
		<main-footer />
		<!-- e: footer -->

		<common-alert></common-alert>
		<address-modal />
	</div>
</template>
<script>
import LoginHeader from './header/LoginHeader';
import MainFooter from './footer/MainFooter';
import CommonAlert from '../../components/common/CommonAlert';
import { mapGetters } from 'vuex';
import AddressModal from '../../components/AddressModal';

export default {
	name: 'LoginLayout',
	components: {
		LoginHeader,
		MainFooter,
		CommonAlert,
		AddressModal,
	},
	computed: {
		...mapGetters('common', ['isMobile', 'address']),
	},
};
</script>
